<template>
  <div class="page flex-row justify-between">
    <menuTabs />
    <div class="page-right">
      <div
        :class="{
          info: true,
          'short-info': userInfo.businessInfo.timeInfo.length <= 2,
        }"
      >
        <div class="info-left flex-row justify-center">
          <div class="head-portrait">
            <img v-if="userInfo.image" :src="userInfo.image" alt="" />
            <img v-else src="@/assets/img/default_head.png" alt="" />
          </div>
          <div class="head-name flex-col">
            <div class="name flex-row align-center">
              <span>{{ userInfo.name }}</span>
            </div>
            <div class="edit flex-row align-center" @click="goEditInfo">
              <span class="txt">编辑资料</span>
              <svg-icon class="edit-svg" iconClass="edit"></svg-icon>
            </div>
          </div>
        </div>
        <div class="cost-money-view flex-col align-center">
          <div class="tips flex-row align-center">
            <span class="txt">您已累计消费</span>
          </div>
          <div class="cost-money flex-row align-center">
            <span>￥</span>
            <span>{{ userInfo.businessInfo.costMoney }}</span>
          </div>
        </div>
      </div>
      <!-- <div
        class="wherever justify-center"
        v-if="newType"
        @click="OpenBrandLogoDislog"
      >
        <img class="why_text" src="../../assets/img/why_text.png" alt="" />
        <img class="goicon" src="../../assets/img/goicon.png" alt="" />
      </div> -->
      <div class="table-title flex-row align-center">
        <div class="border-left"></div>
        <span class="screenTitle-txt"
          >剩余可用总时长，共{{ allScreenTime }}秒</span
        >
      </div>
      <el-tabs v-model="currentTabIndex">
        <el-tab-pane label="按时长类型查看" name="1" />
        <el-tab-pane label="按时长可用屏幕查看" name="2" />
      </el-tabs>
      <div v-if="currentTabIndex == 1">
        <div class="table-th flex-row justify-between align-center">
          <div>时长类型名称</div>
          <div>剩余时长(秒)</div>
          <div>有效期至</div>
          <div>时长可用屏幕</div>
        </div>
        <div
          v-if="
            userInfo.businessInfo.timeInfo &&
            userInfo.businessInfo.timeInfo.length
          "
        >
          <div
            class="table-view"
            v-for="(item, index) in userInfo.businessInfo.timeInfo"
            :key="index"
          >
            <div
              v-if="item.style == 0"
              class="table-td flex-row justify-between align-center"
            >
              <div>
                黄金会员专享（第{{ item.freeVipValidTimes }}个月）送{{
                  item.freeVipSecond
                }}秒
              </div>
              <div>{{ item.freeVipTime }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div v-if="item.freeVipName">
                {{ item.freeVipName | screenFilter }}
              </div>
              <div v-else>全部屏幕</div>
            </div>
            <div
              v-if="item.style == 1"
              class="table-td flex-row justify-between align-center"
            >
              <div>本月1折优惠充值总时长{{ item.buyTime }}秒</div>
              <div>{{ item.buyTimeRemainTime }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div>
                {{ item.buyTimeName | screenFilter }}
              </div>
            </div>
            <div
              v-if="item.style == 2 && item.refund"
              class="table-td flex-row justify-between align-center"
            >
              <div>订单结束退时长{{ item.packageTime }}秒</div>
              <div>{{ item.packageRemainTime }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div>全部屏幕</div>
            </div>
            <div
              v-if="item.style == 2 && !item.refund && !item.packageOnline"
              class="table-td flex-row justify-between align-center"
            >
              <div>
                线下充值{{ item.packageMoney }}元套餐含{{ item.packageTime }}秒
              </div>
              <div>{{ item.packageRemainTime }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div>全部屏幕</div>
            </div>
            <div
              v-if="item.style == 2 && !item.refund && item.packageOnline"
              class="table-td flex-row justify-between align-center"
            >
              <div>表白送祝福{{ item.packageTime }}秒</div>
              <div>{{ item.packageRemainTime }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div>全部屏幕</div>
            </div>
            <div
              v-if="item.style == 3"
              class="table-td flex-row justify-between align-center"
            ></div>
            <div
              v-if="item.style == 4"
              class="table-td flex-row justify-between align-center"
            >
              <div>
                累计消费满¥{{ item.costGiftMoney }}送{{ item.costGiftTime }}秒
              </div>
              <div>{{ item.costGiftRemainTime }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div>全部屏幕</div>
            </div>
            <div
              v-if="item.style == 5"
              class="table-td flex-row justify-between align-center"
            >
              <div>普通充值总时长{{ item.commonBuyTime }}秒</div>
              <div>{{ item.commonBuyTimeRemainTime }}</div>
              <div>永久有效</div>
              <div>
                {{ item.commonBuyTimeName | screenFilter }}
              </div>
            </div>
            <div
              v-if="item.style == 6"
              class="table-td flex-row justify-between align-center"
            >
              <div v-if="item.buyVipPackageType == 'm1'">
                基础版企业套餐{{ item.buyVipSecond }}秒（按月付）
              </div>
              <div v-if="item.buyVipPackageType == 'm2'">
                进取版企业套餐{{ item.buyVipSecond }}秒（按月付）
              </div>
              <div v-if="item.buyVipPackageType == 'm3'">
                尊贵版企业套餐{{ item.buyVipSecond }}秒（按月付）
              </div>
              <div v-if="item.buyVipPackageType == 'y1'">
                基础版企业套餐{{ item.buyVipSecond }}秒（按年付）
              </div>
              <div v-if="item.buyVipPackageType == 'y2'">
                进取版企业套餐{{ item.buyVipSecond }}秒（按年付）
              </div>
              <div v-if="item.buyVipPackageType == 'y3'">
                尊贵版企业套餐{{ item.buyVipSecond }}秒（按年付）
              </div>
              <div>{{ item.second }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div>全部屏幕</div>
            </div>
            <div
              v-if="item.style == 7"
              class="table-td flex-row justify-between align-center"
            >
              <div>参与抽奖奖励</div>
              <div>{{ item.second }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div>全部屏幕</div>
            </div>
            <div
              v-if="item.style == 8"
              class="table-td flex-row justify-between align-center"
            >
              <div>邀请好友奖励</div>
              <div>{{ item.second }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div>全部屏幕</div>
            </div>
            <div
              v-if="item.style == 9"
              class="table-td flex-row justify-between align-center"
            >
              <div>代金券结算时长</div>
              <div>{{ item.second }}</div>
              <div>{{ item.expireTime | dateFormat }}</div>
              <div>全部屏幕</div>
            </div>
          </div>
        </div>
        <div v-else class="table-empty flex-row justify-center align-center">
          暂无数据
        </div>
      </div>
      <div v-else>
        <div class="table-th flex-row justify-between align-center">
          <div>时长所属屏幕</div>
          <div>剩余总时长</div>
        </div>
        <div v-if="totalTimeArr && totalTimeArr.length">
          <div
            class="table-view"
            v-for="(item, index) in totalTimeArr"
            :key="index"
          >
            <div class="table-td flex-row justify-between align-center">
              <div>{{ item[0] | screenFilter }}</div>
              <div>{{ item[1] }}秒</div>
            </div>
          </div>
        </div>
        <div v-else class="table-empty flex-row justify-center align-center">
          暂无数据
        </div>
      </div>
    </div>
    <consume ref="consumeDislog" />
    <BrandLogo ref="BrandLogoDislog" />
  </div>
</template>

<script>
/* eslint-disable-next-line import/no-cycle */
import { getUserInfo } from "@/api/request";
import menuTabs from "@/components/menuTabs.vue";
import consume from "./components/consume.vue";
import BrandLogo from "./components/BrandLogo.vue";

export default {
  components: {
    menuTabs,
    consume,
    BrandLogo,
  },
  data() {
    return {
      totalTimeArr: [],
      currentTabIndex: "1",
      userInfo: {
        businessInfo: {
          isBuyVip: false,
          timeInfo: [],
          buyVipInfo: {},
        },
      },
      packageType: "",
      packageTypeName: "",
      newType: null,
      starRating: 0,
    };
  },
  computed: {
    // 总时长
    allScreenTime() {
      const { timeInfo } = this.userInfo.businessInfo;
      let allScreenTimeNum = 0;
      timeInfo.forEach((item) => {
        switch (item.style) {
          case "0":
            allScreenTimeNum += Number(item.freeVipTime);
            break;
          case "1":
            allScreenTimeNum += Number(item.buyTimeRemainTime);
            break;
          case "2":
            allScreenTimeNum += Number(item.packageRemainTime);
            break;
          case "4":
            allScreenTimeNum += Number(item.costGiftRemainTime);
            break;
          case "5":
            allScreenTimeNum += Number(item.commonBuyTimeRemainTime);
            break;
          case "6":
            allScreenTimeNum += Number(item.second);
            break;
          case "7":
            allScreenTimeNum += Number(item.second);
            break;
          case "8":
            allScreenTimeNum += Number(item.second);
            break;
          case "9":
            allScreenTimeNum += Number(item.second);
            break;
          default:
            break;
        }
      });
      return allScreenTimeNum;
    },
    // 进度条长度
    percentageWidth() {
      const num = this.userInfo.businessInfo.nextCostFinishPercent;
      if (!num) {
        return `width: 0rem`;
      }
      return `width: ${num * 2.04}rem`;
    },
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 查询用户信息
    getUserInfo() {
      getUserInfo({}).then((res) => {
        this.userInfo = res.userInfo;
        if (res.userInfo.businessInfo.isBuyVip) {
          this.packageType = res.userInfo.businessInfo.buyVipInfo.packageType;
          switch (this.packageType) {
            case "m1":
              this.packageTypeName = `基础版企业套餐（按月付）`;
              this.starRating = 1;
              break;
            case "m2":
              this.packageTypeName = `进取版企业套餐（按月付）`;
              this.starRating = 2;
              break;
            case "m3":
              this.packageTypeName = `尊贵版企业套餐（按月付）`;
              this.starRating = 3;
              break;
            case "y1":
              this.packageTypeName = `基础版企业套餐（按年付）`;
              this.starRating = 1;
              break;
            case "y2":
              this.packageTypeName = `进取版企业套餐（按年付）`;
              this.starRating = 2;
              break;
            case "y3":
              this.packageTypeName = `尊贵版企业套餐（按年付）`;
              this.starRating = 3;
              break;
            default:
              break;
          }
        } else {
          this.packageType = null;
          this.packageTypeName = "";
        }
        if (
          this.userInfo.name.substr(0, 4) == "大屏会员" ||
          this.userInfo.image.indexOf(`${this.userInfo.userId}.png`) != -1
        ) {
          this.newType = true;
        }
        this.$store.dispatch("setUserInfoActions", res.userInfo);
        if (res.userInfo.businessInfo.totalTime) {
          this.totalTimeArr = Object.entries(res.userInfo.businessInfo.totalTime);
        }
      });
    },
    goDurationTable() {
      this.$router.push({
        name: "DurationTable",
        query: {
          userId: this.userInfo.businessInfo.userId,
        },
      });
    },
    goEditInfo() {
      this.$router.push({
        name: "EditInfo",
      });
    },
    goProductPrice() {
      if (this.packageType == "m3" || this.packageType == "y1" || this.packageType == "y2") {
        this.$router.push({
          name: "ProductPrice",
          query: {
            active: 2,
          },
        });
      } else {
        this.$router.push({ name: "ProductPrice" });
      }
    },
    // 累计消费显示弹窗
    consumeClick() {
      this.$refs.consumeDislog.open();
    },
    OpenBrandLogoDislog() {
      this.$refs.BrandLogoDislog.open();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .consumption {
  background: #f5f5f5 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
  height: 100%;
}
::v-deep .el-tabs__active-bar {
  background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
  // width: 112rem !important;
  height: 2rem;
}
::v-deep .el-tabs__nav {
  height: 52rem;
}
::v-deep .el-tabs__item {
  height: 50rem;
  line-height: 50rem;
  position: relative;
  top: 2rem;
  font-size: 16rem;
  padding: 0 20rem;
}
.page {
  width: 100%;
  background-color: #fff;
  min-height: calc(100vh - 233rem);
  padding: 60rem 320rem 0;
  .page-right {
    width: 1060rem;
    margin-bottom: 100rem;
    .info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #f8f8f8;
      border-radius: 12rem 12rem 0 0;
      padding: 0 20rem 20rem 36rem;
      .info-left {
        .head-portrait {
          width: 110rem;
          height: 110rem;
          border-radius: 22rem;
          border: 1rem solid rgba(0, 0, 0, 0.05);
          overflow: hidden;
          position: relative;
          margin-top: 36rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .head-name {
          margin: 44rem 0 0 29rem;
          .name {
            span {
              font-size: 20rem;
              color: #252927;
              line-height: 28rem;
            }
            img {
              width: 24rem;
              margin-left: 14rem;
            }
          }
          .edit {
            margin-top: 8rem;
            cursor: pointer;
            .txt {
              font-size: 16rem;
              color: #7c7c7c;
              line-height: 22rem;
            }
            .edit-svg {
              color: #b0b0b0;
              width: 14rem;
              height: 14rem;
              margin-left: 5rem;
            }
            &:hover {
              .edit-svg {
                color: #898989;
              }
            }
          }
          .payDuration_entrance {
            width: 259rem;
            margin-top: 14rem;
            position: relative;
            right: 6rem;
            cursor: pointer;
          }
        }
      }
      .cost-money-view {
        width: 390rem;
        height: 141rem;
        background: #ffffff;
        border-radius: 12rem;
        margin-top: 20rem;
        .tips {
          margin-top: 40rem;
          .txt {
            font-size: 14rem;
            color: #252927;
            line-height: 20rem;
          }
          .reference {
            cursor: pointer;
            margin-left: 6rem;
            .service-svg {
              color: #696969;
              width: 20rem;
              height: 20rem;
            }

            &:hover {
              .service-svg {
                color: #32c3f6;
              }
            }
          }
        }
        .cost-money {
          margin-top: 7rem;
          span {
            &:nth-child(1) {
              font-size: 22rem;
              color: #252927;
              line-height: 30rem;
              margin-right: 2rem;
            }
            &:nth-child(2) {
              font-size: 28rem;
              color: #252927;
              line-height: 40rem;
            }
          }
        }
        .progress-view {
          width: 204rem;
          height: 8rem;
          background: #d8d8d8;
          border-radius: 5rem;
          margin-top: 10rem;
          position: relative;
          .percentage {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 5rem;
            background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
          }
        }
        .desc {
          font-size: 13rem;
          // color: #2fd3e7;
          line-height: 18rem;
          margin-top: 10rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .wherever {
      height: 45rem;
      background: url("../../assets/img/brandShow.png");
      background-size: 100% 100%;
      cursor: pointer;
      .why_text {
        width: 302rem;
        height: 23rem;
        margin-right: 8rem;
        margin-top: 13rem;
      }
      .goicon {
        margin-top: 15rem;
        width: 16rem;
        height: 16rem;
      }
    }
    .short-info {
      .info-left {
        .head-portrait {
          margin-top: 24rem;
        }
        .head-name {
          margin-top: 32rem;
        }
      }
    }
    .table-title {
      width: 100%;
      height: 60rem;
      background: #f8f8f8;
      border-radius: 6rem 6rem 0px 0px;
      margin-top: 49rem;
      padding-left: 26rem;
      .border-left {
        width: 3rem;
        height: 18rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      }
      span {
        font-size: 18rem;
        color: #252927;
        line-height: 25rem;
        margin-left: 14rem;
      }
    }
    .el-tabs {
      width: 100%;
      height: 52rem;
      background: #f8f8f8;
      padding-left: 36rem;
      margin: 8rem 0 2rem 0;
    }
    .table-th {
      width: 100%;
      height: 52rem;
      background: #f8f8f8;
      border-radius: 6rem 6rem 0px 0px;
      padding: 0 36rem;
      div {
        text-align: center;
        font-size: 16rem;
        color: #9a9c9a;
        line-height: 22rem;
        &:nth-child(1) {
          text-align: left;
          width: 380rem;
        }
        &:nth-child(2) {
          width: 120rem;
        }
        &:nth-child(3) {
          width: 368rem;
        }
        &:nth-child(4) {
          text-align: right;
          width: 120rem;
        }
      }
    }
    .table-view {
      width: 100%;
      height: 52rem;
      background: #f8f8f8;
      border-radius: 6rem 6rem 0px 0px;
      padding: 0 36rem;
      margin-top: 2rem;
      .table-td {
        width: 100%;
        height: 100%;
        div {
          text-align: center;
          font-size: 16rem;
          line-height: 22rem;
          color: #3b3e3c;
          &:nth-child(1) {
            text-align: left;
            width: 380rem;
          }
          &:nth-child(2) {
            width: 120rem;
          }
          &:nth-child(3) {
            width: 368rem;
          }
          &:nth-child(4) {
            text-align: right;
            width: 120rem;
          }
        }
      }
    }
    .table-empty {
      width: 100%;
      height: 106rem;
      margin-top: 2rem;
      background: #f8f8f8;
      font-size: 16rem;
      color: #9a9c9a;
      line-height: 22rem;
    }
    .ordinary {
      width: 1060rem;
      margin-top: 127rem;
      .ordinary-title {
        font-size: 28rem;
        color: #252927;
        line-height: 40rem;
      }
      .ordinary-desc {
        margin: 27rem 0 50rem 0;
        img {
          width: 24rem;
          margin-right: 3rem;
        }
        span {
          font-size: 18rem;
          font-weight: 400;
          color: #32c3f6;
          line-height: 25rem;
          margin-left: 10rem;
        }
      }
      .popover {
        margin-top: 50rem;
        .service-txt {
          width: 210rem;
          height: 48rem;
          background: linear-gradient(225deg, #4facfe 0%, #09d5df 100%);
          border-radius: 27rem;
          text-align: center;
          line-height: 48rem;
          font-size: 16rem;
          font-weight: 500 !important;
          color: #ffffff;
        }
      }
      .popover-up {
        margin-top: 50rem;
        .service-txt {
          padding: 13rem 20rem;
          background: linear-gradient(225deg, #4facfe 0%, #09d5df 100%);
          border-radius: 27rem;
          text-align: center;
          font-size: 16rem;
          font-weight: 400 !important;
          color: #ffffff;
        }
      }
      .service {
        margin-top: 35rem;
        font-size: 16px;
        color: #252927;
      }
    }
  }
  .dialig_Box {
    padding: 10rem 0 0 55rem;
    .title-box {
      height: 33rem;
      font-size: 24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #252927;
      line-height: 33rem;
      display: flex;
      align-items: center;
      img {
        width: 48rem;
        height: 48rem;
        margin-right: 28rem;
      }
    }
    .text-box {
      margin-top: 30rem;
      display: flex;
      .icon {
        width: 3rem;
        margin: 4rem 14rem 0 0;
        height: 18rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      }
      .text-time {
        display: block;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #252927;
      }
      .details {
        height: 22rem;
        font-size: 16rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9a9c9a;
        line-height: 22rem;
        margin-bottom: 5rem;
      }
    }
    .flow {
      width: 604rem;
      height: 130rem;
      background: #ffffff;
      border-radius: 12rem;
      margin: 30rem 17rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
