<template>
  <el-dialog :visible="hintVisible" :append-to-body="true"
  :close-on-click-modal="false" width="1056rem"
    custom-class="hint-dialog" @close="hintVisible = false">
    <div slot="title" class="title_top">为什么设置品牌名称和LOGO很重要？</div>
    <div class="box_hintDialog">
      <div class="describe">品牌名称和LOGO会在官网以及分享页面展示，设置后可以让更多人看到您的品牌，这将会起到一个很好的宣传作用</div>
      <div class="title_hintDialog">
        <div class="icon"></div>
        <div>分享页面</div>
      </div>
      <div class="img_hintDialog justify-center">
        <img src="@/assets/img/editInfo_hint1.png" alt="">
      </div>
      <div class="title_hintDialog">
        <div class="icon"></div>
        <div>
          官网首页
        </div>
      </div>
      <div class="img_hintDialogB justify-center">
        <img src="@/assets/img/editInfo_hint2.png" alt="">
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      hintVisible: false,
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    open() {
      this.hintVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .hint-dialog {
  .el-dialog__header {
    font-size: 24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #252927;
    line-height: 33rem;
    text-align: center;
  }

  .el-dialog__body {
    padding: 0rem 70rem;
    margin-bottom: 28rem;
    height: 600rem;
    overflow-x: hidden;

    .box_hintDialog {
      width: 916rem;
      padding-bottom:58rem;

      .describe {
        height: 25rem;
        font-size: 18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #252927;
        line-height: 25rem;
        margin-bottom: 50rem;
        text-align: center;
      }

      .title_hintDialog {
        margin-bottom: 22rem;
        margin-left: 56rem;
        display: flex;
        align-items: center;
        height: 25rem;
        font-size: 18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #252927;
        line-height: 25rem;

        .icon {
          width: 3rem;
          height: 18rem;
          margin-right: 14rem;
          background: linear-gradient(225deg, #4FACFE 0%, #0AE1EC 100%);
        }
      }

      .img_hintDialog {
        margin-bottom: 48rem;

        img {
          width: 804rem;
          height: 596rem;
        }
      }

      .img_hintDialogB {
        height: 596rem;

        img {
          height: 100%;
          width: 804rem;
        }
      }
    }
  }
}
</style>
