<template>
  <el-dialog :visible.sync="dialogVisible" width="862rem" center custom-class="consumption">
    <div class="dialig_Box">
      <div class="title-box">
        <img src="@/assets/img/leijiicon.png" alt="">
        <div>累计消费奖励</div>
      </div>
      <div class="text-box">
        <div class="icon"></div>
        <div class="text-time">活动时间：长期有效</div>
      </div>
      <div class="text-box">
        <div class="icon"></div>
        <div class="text-time">活动内容：活动期间，累计消费达到一定额度，即可赠送广告时长</div>
      </div>
      <div class="flow">
        <img src="@/assets/img/leijiImg.png" alt="">
      </div>
      <div class="text-box">
        <div class="icon"></div>
        <div class="text-time">
          <div style="margin-bottom:12rem">注意：</div>
          <div class="details">
            *赠送时长全屏幕通用，有效期为365天
          </div>
          <div class="details">
            *累计消费满5万元后，每次消费满5万元，均可获得5万秒广告时长奖励
          </div>
          <div class="details">
            *订单消费金额需投放结束后（有退款的则需完成退款）才会纳入累计消费
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .consumption {
  background: #F5F5F5 !important;
}
.dialig_Box {
  padding: 10rem 0 0 55rem;

  .title-box {
    height: 33rem;
    font-size: 24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #252927;
    line-height: 33rem;
    display: flex;
    align-items: center;

    img {
      width: 48rem;
      height: 48rem;
      margin-right: 28rem;
    }
  }

  .text-box {
    margin-top: 30rem;
    display: flex;

    .icon {
      width: 3rem;
      margin: 4rem 14rem 0 0;
      height: 18rem;
      background: linear-gradient(225deg, #4FACFE 0%, #0AE1EC 100%);
    }

    .text-time {
      width: 610rem;
      display: block;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #252927;
      line-height: 25rem;
      font-size: 18rem;
    }

    .details {
      font-size: 16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9A9C9A;
      line-height: 25rem;
      margin-bottom: 5rem;
    }
  }

  .flow {
    width: 604rem;
    height: 130rem;
    background: #FFFFFF;
    border-radius: 12rem;
    margin: 30rem 17rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}</style>
